
:root {
    --msj-blue-light: rgb(75, 125, 134);
    --msj-blue: rgb(46, 96, 105);
    --msj-blue-dark: rgb(14, 62, 61);
    --msj-blue-transparent: rgba(14, 62, 61, 0.3);
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('fonts/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}


body {
    font-family: Poppins, sans-serif;
}

.mapboxgl-control-container {
    font-family: Poppins, sans-serif;
}

.form-control:focus,
.form-select:focus,
.btn:focus,
.pagination .page-item.active,
.pagination .page-item:focus {
    border-color: var(--msj-blue);
    box-shadow: 0 0 0 3px var(--msj-blue-transparent)
}

a {
    color: var(--msj-blue);
}

a:hover {
    color: var(--msj-blue-dark);
}

.nav-pills .nav-link.active {
    background-color: var(--msj-blue);
}

.nav-pills .nav-link {
    color: var(--msj-blue);
}

.btn-primary, .badge-primary {
    background-color: var(--msj-blue);
    border-color: var(--msj-blue);
}

:not(.btn-check) + .btn:hover,
.btn:first-child:hover,
.btn-primary:hover, .btn-outline-primary:hover,
.btn-primary:first-child:hover, .btn-outline-primary:first-child:hover {
    background-color: var(--msj-blue-dark);
    border-color: var(--msj-blue-dark);
}

.btn-outline-primary {
    border-color: var(--msj-blue);
    color: var(--msj-blue);
}

.btn-primary[disabled] {
    background-color: var(--msj-blue-light);
    border-color: var(--msj-blue-light);
}

.pagination .page-item.active .page-link {
    background-color: var(--msj-blue);
    border-color: var(--msj-blue);
    color: white;
    z-index: 1;
}

.pagination .page-item .page-link {
    color: var(--msj-blue);
}

